export default {
  title: "مدیریت ادمین‌ها",
  addNew: "افزودن ادمین جدید",
  edit: "ویرایش ادمین",
  firstName: "نام",
  firstNamePlaceholder: "نام ادمین را وارد کنید",
  lastName: "نام خانوادگی",
  lastNamePlaceholder: "نام خانوادگی ادمین را وارد کنید",
  password: "رمز عبور",
  passwordPlaceholder: "رمز عبور ادمین را وارد کنید",
  email: "ایمیل",
  emailPlaceholder: "ایمیل ادمین را وارد کنید",
  personalInfo: "مشخصات فردی",
  permissions: "دسترسی‌ها",
  superAdmin: "دسترسی سوپر ادمین",
  passwordLenError: "رمز عبور باید بیشتر از 8 کاراکتر باشد",
  status: "وضعیت حساب",
  statusActive: "فعال",
  statusInactive: "غیرفعال",
  permissionLabels: {
    ReadAdmin: "مشاهده ادمین‌ها",
    WriteAdmin: "ویرایش ادمین‌ها",
    ReadBrand: "مشاهده برندها",
    WriteBrand: "ویرایش برندها",
    ReadCategory: "مشاهده دسته‌بندی‌ها",
    WriteCategory: "ویرایش دسته‌بندی‌ها",
    ReadSubCategory: "مشاهده زیردسته‌بندی‌ها",
    WriteSubCategory: "ویرایش زیردسته‌بندی‌ها",
    ReadTag: "مشاهده تگ‌ها",
    WriteTag: "ویرایش تگ‌ها",
    ReadModel: "مشاهده مدل‌ها",
    WriteModel: "ویرایش مدل‌ها",
    ReadColor: "مشاهده رنگ‌ها",
    WriteColor: "ویرایش رنگ‌ها",
    ReadProduct: "مشاهده محصول‌ها",
    WriteProduct: "ویرایش محصول‌ها",
    ReadComment: "مشاهده نظرات",
    WriteComment: "ویرایش نظرات",
    ReadOrder: "مشاهده سفارش‌ها",
    ReadRepairs: "مشاهده تعمیرات",
    WriteRepairs: "ویرایش تعمیرات",
    WriteOrder: "ویرایش سفارش‌ها",
    ReadUser: "مشاهده کاربران",
    WriteUser: "ویرایش کاربران",
    ReadStatics: "مشاهده اطلاعات وبسایت",
    WriteStatics: "ویرایش اطلاعات وبسایت",
    WriteStudio: "ویرایش استودیو‌ها",
    ReadStudio: "مشاهده استودیو‌ها",
    WriteGuitar: "ویرایش گیتار‌ها",
    ReadGuitar: "مشاهده گیتار‌ها",
    WriteBase: "ویرایش پایه‌ها",
    ReadBase: "مشاهده بیس‌ها",
    WriteKeys: "ویرایش کلید‌ها",
    ReadKeys: "مشاهده کلید‌ها",
    WriteAccessories: "ویرایش اکسسوری‌ها",
    ReadAccessories: "مشاهده اکسسوری‌ها",
    ReadLog: "مشاهده لاگ‌ها",
    ReadUsedProducts: "مشاهده محصولات دست دوم",
    WriteUsedProducts: "ویرایش محصولات دست دوم",
  },
};
