import { useLoaderData, useNavigate } from "react-router-dom";
import type { TInspectionHistoryItem, TInspectionListData, TSetInspectionBody } from "@/types/used-product.type.ts";
import { FormProvider, useForm } from "react-hook-form";
import Page from "@/components/page.tsx";
import { useTranslation } from "react-i18next";
import { Button, buttonVariants } from "@/components/ui/button.tsx";
import { Loader2, RefreshCcwIcon } from "lucide-react";
import { z } from "zod";
import { healthSchema } from "@/components/product-form/schema.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import HealthInspectionTable from "@/components/product-form/health-inspection/table.tsx";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { getRequiredStrSchema } from "@/lib/utils/validation.ts";
import { toast } from "@/components/ui/use-toast.ts";
import { type KeyboardEvent, useEffect, useState } from "react";
import { ProductAPI } from "@/api";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx";
import InspectionHistory from "@/pages/health-inspection/history.tsx";

const schema = z.object({
  product_name: getRequiredStrSchema(),
  series_id: getRequiredStrSchema(),
  items: healthSchema,
});

export default function HealthInspectionPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pageTitle = t("healthInspection.title");
  const healthData = useLoaderData() as TInspectionListData;
  const [history, setHistory] = useState<TInspectionHistoryItem[]>();
  const [loadingHistory, setLoadingHistory] = useState(false);

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      product_name: "",
      series_id: "",
      items: healthData,
    },
  });
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  async function submit(data: TSetInspectionBody) {
    const { report_url } = await ProductAPI.setInspection(data);
    toast({ description: t("common.successMessage") });
    window.open(report_url, "_blank");
    navigate("/");
  }

  function handleKeyDown(e: KeyboardEvent<HTMLFormElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  async function getHistory(open: boolean) {
    if (open && !history) {
      setLoadingHistory(true);
      setHistory(await ProductAPI.getInspectionHistory());
      setLoadingHistory(false);
    }
  }

  useEffect(() => {
    if (errors.items) toast({ variant: "destructive", description: t("healthInspection.tableErr") });
  }, [errors]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} onKeyDown={handleKeyDown}>
        <Page
          breadcrumbs={[
            { label: t("sidebar.home"), link: "/" },
            { label: t("productsManagement.title"), link: "/products" },
            { label: pageTitle },
          ]}
          title={pageTitle}
          action={
            <div className="flex gap-5">
              <Dialog onOpenChange={getHistory}>
                <DialogTrigger className={buttonVariants({ variant: "secondary", className: "w-[129px]" })}>
                  {loadingHistory ? <Loader2 className="animate-spin" /> : t("healthInspection.previousProducts")}
                </DialogTrigger>
                <DialogContent className="max-w-3xl">
                  <DialogHeader>
                    <DialogTitle>{t("healthInspection.history")}</DialogTitle>
                    <DialogDescription></DialogDescription>
                  </DialogHeader>
                  {history !== undefined && <InspectionHistory history={history} />}
                </DialogContent>
              </Dialog>
              <Button type="submit" variant="secondary" loading={isSubmitting}>
                {t("healthInspection.fileDownload")}
              </Button>
              <Button variant="secondary" size="icon" onClick={() => reset()}>
                <RefreshCcwIcon />
              </Button>
            </div>
          }
        >
          <div className="bg-gray-scale-5 flex flex-col">
            <div className="p-4 flex gap-2.5">
              <FormField
                control={control}
                name="product_name"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t("healthInspection.model")}</FormLabel>
                    <FormControl>
                      <Input className="bg-white/5" placeholder={t("healthInspection.modelPlaceholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="series_id"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t("healthInspection.seriesNumber")}</FormLabel>
                    <FormControl>
                      <Input className="bg-white/5" placeholder={t("healthInspection.seriesPlaceholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <HealthInspectionTable name="items" />
          </div>
        </Page>
      </form>
    </FormProvider>
  );
}
