import { StickyTab, StickyTabs } from "@/components/ui/sticky-tabs.tsx";
import { useState } from "react";
import type { TRepairPrice, TRepairService, TRepairServices, TRepairTime } from "@/types/repair.type.ts";
import { addCommas } from "@persian-tools/persian-tools";
import { cn } from "@/lib/utils.ts";
import { dict } from "@/dictionary";

const RANGE_SEPARATOR = " _ ";

type ServicesTableProps = {
  services: TRepairServices;
  className?: string;
  onTabSwitch?: (serviceType: TRepairService["type"]) => void;
};

export default function ServicesTable({ services: { items, meta_data }, className, onTabSwitch }: ServicesTableProps) {
  const serviceTypes = Object.keys(items);
  const [activeServiceType, setActiveServiceType] = useState(serviceTypes[0]);
  const activeServices = items[activeServiceType];

  function changeTab(serviceType: string) {
    if (serviceType !== activeServiceType) {
      setActiveServiceType(serviceType);
      onTabSwitch?.(serviceType);
    }
  }

  function renderTime({ start, end, unit }: TRepairTime) {
    return (
      <span className="flex items-center gap-1">
        {[start, end].join(RANGE_SEPARATOR)}
        <span className="text-gray-scale-4">{dict.repairs[unit]}</span>
      </span>
    );
  }

  function renderPrice({ is_free, start_range, end_range, unit }: TRepairPrice) {
    return is_free ? (
      dict.repairs.free
    ) : (
      <span className="flex gap-1">
        {start_range === end_range ? start_range : [start_range, end_range].map(addCommas).join(RANGE_SEPARATOR)}
        <span className="text-gray-scale-4">{dict.repairs[unit]}</span>
      </span>
    );
  }

  return (
    <div className={className}>
      <StickyTabs value={activeServiceType} onChange={changeTab}>
        {serviceTypes.map((serviceType) => (
          <StickyTab key={serviceType} label={meta_data[serviceType].fa} value={serviceType} />
        ))}
      </StickyTabs>
      <div className="dark:bg-white/5 bg-[#1313130d] p-5 space-y-5">
        {Object.values(activeServices).map((categoryItems, categoryIndex) => (
          <div key={categoryIndex} className="flex max-md:flex-col md:items-center gap-1 md:gap-5">
            <div
              className={cn(
                "flex md:flex-col gap-1 md:w-[6rem] md:text-center ltr:font-montserrat",
                !categoryIndex && "md:mt-9",
              )}
            >
              <span className="text-xs md:text-sm whitespace-pre-line">{dict.repairs[categoryItems[0].category]}</span>
            </div>
            <div className="flex-1">
              {!categoryIndex && (
                <div className="grid grid-cols-[1.5fr_repeat(3,1fr)] gap-3 text-[10px] md:text-xl max-md:text-gray-scale-4 mb-1 md:mb-5">
                  <span>{dict.repairs.service}</span>
                  <span>{dict.repairs.duration}</span>
                  <span>{dict.repairs.price}</span>
                  <span>{dict.repairs.note}</span>
                </div>
              )}
              <div className="border border-black/10 dark:border-white/10 divide-y divide-black/10 dark:divide-white/10 ltr:font-montserrat">
                {categoryItems.map(({ title, time, price, note }, categoryItemIndex) => (
                  <div
                    className="grid grid-cols-[1.5fr_repeat(3,1fr)] gap-3 items-center text-xs md:text-sm px-1.5 py-1"
                    key={`${categoryIndex}-${categoryItemIndex}`}
                  >
                    <span className="max-md:w-full text-gray-scale-4">{title.fa}</span>
                    {renderTime(time)}
                    {renderPrice(price)}
                    <span>{note || "-"}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
