export const healthInspection = {
  title: "سلامت سنجی محصول",
  previousProducts: "محصولات گذشته",
  fileDownload: "دانلود فایل",
  model: "مدل",
  seriesNumber: "شماره سریال",
  modelPlaceholder: "مدل ساز را وارد کنید",
  seriesPlaceholder: "شماره سریال ساز را وارد کنید",
  tableErr: "لطفا امتیاز و مقدار موارد انتخاب شده را وارد کنید",
  history: "محصولات سلامت سنجی شده",
};
