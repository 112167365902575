import { Trans } from "react-i18next";
import Rating from "@/components/rating.tsx";
import { TComment } from "@/types/comment.ts";

export default function CommentOverview({ user, rating_star, date, title, body }: TComment) {
  return (
    <>
      <header className="flex items-center">
        <div className="text-xl flex-1">
          {user.name}&nbsp;
          {user.purchased_before ? (
            <span className="text-xs inline-block p-2 text-primary bg-white/5">
              <Trans i18nKey="comments.purchasedUser" />
            </span>
          ) : null}
        </div>
        <div className="flex flex-col items-end">
          <div className="flex items-center gap-2 dir-ltr">
            <span className="text-sm text-gray-scale-3">{rating_star}</span>
            <Rating stars={rating_star} className="flex-row-reverse" />
          </div>
          <span className="text-sm text-gray-scale-3">
            {new Intl.DateTimeFormat("fa-IR", { day: "2-digit", month: "long", year: "numeric" }).format(
              new Date(date),
            )}
          </span>
        </div>
      </header>
      <div className="space-y-1 pb-3 border-b border-white/5">
        <h3>{title}</h3>
        <p>{body}</p>
      </div>
    </>
  );
}
