import Logo from "@/assets/images/logo.svg";
import Mexico from "@/assets/images/mexico.svg";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "@/consts/config.const";
import { cn, hasPermissions } from "@/lib/utils";
import Cookies from "js-cookie";
import { Loader2, LogOut, X } from "lucide-react";
import { Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAuth } from "../providers/auth-provider";
import { Button } from "../ui/button";
import menuItems from "./menuItems";

export default function Sidebar({ toggleOpen, open }: { toggleOpen: () => void; open: boolean }) {
  const { setIsLoggedIn, setProfile, profile } = useAuth();

  const onLogout = () => {
    Cookies.remove(ACCESS_TOKEN_NAME);
    Cookies.remove(REFRESH_TOKEN_NAME);
    setIsLoggedIn(false);
    setProfile(null);
    window.location.href = "/auth/login";
  };

  return (
    <aside
      className={cn("w-[320px] duration-150 bg-gray-scale-5 fixed h-screen top-0 right-0 p-4 flex flex-col", {
        "translate-x-0": open,
        "translate-x-full": !open,
      })}
    >
      <Button
        variant="ghost"
        size="icon"
        onClick={toggleOpen}
        className={cn("!absolute top-0 right-0", { "opacity-0": !open })}
      >
        <X size={20} />
      </Button>
      <div className="flex flex-col gap-4 h-full">
        <div className="flex-shrink-0  flex flex-col gap-2 items-center justify-center">
          <img src={Mexico} className="rounded-full w-36 h-36 p-px border-2 border-primary" />
          <div className="flex flex-col items-center">
            <span>
              {profile?.first_name}&nbsp;{profile?.last_name}
            </span>
            <span className="text-gray-scale-4 text-sm font-montserrat">{profile?.email}</span>
          </div>
        </div>
        <div className="flex-shrink-0  flex items-center justify-center">
          <Button variant="ghost-destructive" size="icon" onClick={onLogout}>
            <LogOut size={20} />
          </Button>
        </div>
        <ul className="flex-auto flex flex-col overflow-y-auto gap-2 px-8">
          {menuItems
            .filter((item) => hasPermissions(profile, item.permissions))
            .map((item) => (
              <li key={item.link}>
                <NavLink
                  className={({ isActive, isPending }) =>
                    cn("bg-gray-scale-4/30 flex items-center justify-center px-3 py-2", {
                      "hover:bg-gray-scale-4/40 duration-100 transition-all hover:text-primary": !isPending,
                      "text-primary": isActive,
                    })
                  }
                  to={item.link}
                >
                  {({ isActive, isPending }) => (
                    <div className="flex items-center gap-2">
                      {isPending && <Loader2 className="animate-spin opacity-80" />}
                      {isActive && <div className="w-2 h-2 bg-primary mb-1.5" />}
                      <span>{item.name}</span>
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
        </ul>

        <div className="flex-shrink-0 flex items-center justify-center">
          <div className="flex flex-col items-center gap-0.5">
            <div className="w-20">
              <img src={Logo} alt="logo" />
            </div>
            <span className="text-[8px] capitalize tracking-[4.5px]">
              <Trans i18nKey="sidebar.brandName" />
            </span>
          </div>
        </div>
      </div>
    </aside>
  );
}
