import { API } from "@/lib/axios";
import type { TAdminReplyBody, TCommentsQuery } from "@/types/comment";
import type { TCommentsList, TMutationResponse, TTranslatedText } from "@/types/general";
import { AxiosResponse } from "axios";

const ENTITY = "/product/comments";

export const getList = async (query: TCommentsQuery): Promise<AxiosResponse<{ data: TCommentsList }>> =>
  API.post(`${ENTITY}/list`, {
    pagination: {
      page: query.page,
      limit: query.limit,
    },
    filter: query.status,
  });

export const confirmById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/confirm`, { id });

export const rejectById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/reject`, { id });

export const deleteById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${id}`);

export async function replyByAdmin(body: TAdminReplyBody): Promise<TMutationResponse> {
  const { data } = await API.patch(`${ENTITY}/admin-comment`, body);
  return data.data;
}
