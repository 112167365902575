import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table.tsx";
import { NewCheckbox } from "@/components/ui/check-base-inputs.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useTranslation } from "react-i18next";
import { TInspectionListData, TUpdateCategoryItemArgs } from "@/types/used-product.type.ts";
import { useFormContext } from "react-hook-form";

type HealthInspectionTableProps = {
  name?: string;
};

export default function HealthInspectionTable({ name = "healthTable" }: HealthInspectionTableProps) {
  const { t } = useTranslation();
  const { setValue, watch, getValues } = useFormContext();
  const healthData: TInspectionListData = watch(name);

  function updateCategoryItem({ type, index, mutate }: TUpdateCategoryItemArgs) {
    const health: TInspectionListData = getValues(name);
    mutate(health[type].items[index]);
    setValue(name, health);
  }

  return (
    <div className="bg-gray-scale-5 p-4 max-h-[35rem] overflow-y-auto">
      <Table>
        <TableHeader>
          <TableRow>
            {tableHeadItems.map(({ title, className }) => (
              <TableHead key={title} className={className}>
                {t(title)}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.values(healthData || {}).map(({ id, items, type }) =>
            items.map(({ title, status, rating, value, note }, index) => (
              <TableRow key={id + index}>
                <TableCell className="text-gray-scale-3">
                  <NewCheckbox
                    checked={Boolean(status)}
                    onChange={({ target: { checked } }) =>
                      updateCategoryItem({
                        type,
                        index,
                        mutate: (categoryItem) => (categoryItem.status = checked),
                      })
                    }
                  />
                </TableCell>
                <TableCell className="text-gray-scale-3">{title.fa}</TableCell>
                <TableCell>
                  <Input
                    placeholder={t("products.healthCheck.actualValue")}
                    disabled={!status}
                    type="number"
                    value={rating || ""}
                    onChange={({ target: { value } }) =>
                      updateCategoryItem({
                        type,
                        index,
                        mutate: (categoryItem) => (categoryItem.rating = +value),
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    placeholder={t("products.healthCheck.value")}
                    disabled={!status}
                    value={value || ""}
                    onChange={({ target: { value } }) =>
                      updateCategoryItem({
                        type,
                        index,
                        mutate: (categoryItem) => (categoryItem.value = value),
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    placeholder={t("products.healthCheck.note")}
                    disabled={!status}
                    value={note || ""}
                    onChange={({ target: { value } }) =>
                      updateCategoryItem({
                        type,
                        index,
                        mutate: (categoryItem) => (categoryItem.note = value),
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            )),
          )}
        </TableBody>
      </Table>
    </div>
  );
}

const tableHeadItems = [
  { title: "products.healthCheck.status" },
  { title: "products.healthCheck.properties" },
  { title: "products.healthCheck.actualValue" },
  { title: "products.healthCheck.value" },
  { title: "products.healthCheck.note", className: "w-[450px]" },
];
