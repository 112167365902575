import type { TSetInspectionBody } from "@/types/used-product.type.ts";

export function prepareInspectionBody(body: TSetInspectionBody) {
  const scoredBody = structuredClone(body);
  let totalScores = 0;
  let totalMultipliers = 0;

  Object.values(scoredBody.items).forEach((category) => {
    let sumOfItemScores = 0;
    let sumOfItemMultipliers = 0;

    category.items.forEach((item) => {
      const { status, rating, rating_multiplier } = item;
      if (status) {
        const itemScore = rating! * rating_multiplier;
        item.rating_multiplier_result = itemScore;

        sumOfItemScores += itemScore;
        sumOfItemMultipliers += rating_multiplier;

        totalScores += itemScore;
        totalMultipliers += rating_multiplier;
      } else {
        item.status = false;
      }
    });

    category.score = calculateScore(sumOfItemScores, sumOfItemMultipliers);
  });

  scoredBody.final_score = calculateScore(totalScores, totalMultipliers);
  return scoredBody;
}

function calculateScore(sum: number, total: number) {
  return +(sum / total).toFixed(2) || 0;
}
