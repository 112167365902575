import { TAdminPermissions } from "@/types/admin";

export const ADMIN_STATUS = {
  ACTIVE: "active",
  INACTIVE: "deactivate",
} as const;

export const ADMIN_PERMISSIONS = {
  READ_ADMIN: "ReadAdmin",
  WRITE_ADMIN: "WriteAdmin",
  READ_BRAND: "ReadBrand",
  WRITE_BRAND: "WriteBrand",
  READ_CATEGORY: "ReadCategory",
  WRITE_CATEGORY: "WriteCategory",
  READ_SUB_CATEGORY: "ReadSubCategory",
  WRITE_SUB_CATEGORY: "WriteSubCategory",
  READ_TAG: "ReadTag",
  WRITE_TAG: "WriteTag",
  READ_PRODUCT: "ReadProduct",
  WRITE_PRODUCT: "WriteProduct",
  READ_ORDER: "ReadOrder",
  WRITE_ORDER: "WriteOrder",
  READ_USER: "ReadUser",
  WRITE_USER: "WriteUser",
  READ_MODEL: "ReadModel",
  WRITE_MODEL: "WriteModel",
  READ_STATICS: "ReadStatics",
  WRITE_STATICS: "WriteStatics",
  READ_COMMENT: "ReadComment",
  WRITE_COMMENT: "WriteComment",
  READ_REPAIRS: "ReadRepairs",
  WRITE_REPAIRS: "WriteRepairs",
  // what are these?
  READ_COLOR: "ReadColor",
  WRITE_COLOR: "WriteColor",
  // categories permissions
  WRITE_STUDIO: "WriteStudio",
  READ_STUDIO: "ReadStudio",
  WRITE_GUITAR: "WriteGuitar",
  READ_GUITAR: "ReadGuitar",
  WRITE_BASE: "WriteBase",
  READ_BASE: "ReadBase",
  WRITE_KEYS: "WriteKeys",
  READ_KEYS: "ReadKeys",
  WRITE_ACCESSORIES: "WriteAccessories",
  READ_ACCESSORIES: "ReadAccessories",
  READ_LOG: "ReadLog",
  READ_USED_PRODUCTS: "ReadUsedProducts",
  WRITE_USER_PRODUCTS: "WriteUsedProducts",
} as const;

export const CATEGORIES_PERMISSIONS_TO_CATEGORIES_ID: Partial<Record<TAdminPermissions, string>> = {
  // production
  // [ADMIN_PERMISSIONS.READ_GUITAR]: "649f178bdb04e1fd6acf19c3",
  // [ADMIN_PERMISSIONS.READ_BASE]: "649f178bdb04e1fd6acf19c7",
  // [ADMIN_PERMISSIONS.READ_KEYS]: "649f178bdb04e1fd6acf19cf",
  // [ADMIN_PERMISSIONS.READ_ACCESSORIES]: "649f178bdb04e1fd6acf19d3",
  // [ADMIN_PERMISSIONS.READ_STUDIO]: "649f178bdb04e1fd6acf19cb",

  // staging
  [ADMIN_PERMISSIONS.READ_GUITAR]: "649f178bdb04e1fd6acf19c3",
  [ADMIN_PERMISSIONS.READ_BASE]: "649f178bdb04e1fd6acf19c7",
  [ADMIN_PERMISSIONS.READ_KEYS]: "649f178bdb04e1fd6acf19cf",
  [ADMIN_PERMISSIONS.READ_ACCESSORIES]: "649f178bdb04e1fd6acf19d3",
  [ADMIN_PERMISSIONS.READ_STUDIO]: "649f178bdb04e1fd6acf19cb",
} as const;
