import { RepairAPI } from "@/api";
import type { TRepairLoaderData } from "@/types/repair.type.ts";

export default async function loadRepairsData(): Promise<TRepairLoaderData> {
  const [content, services] = await Promise.all([RepairAPI.getContent(), RepairAPI.getServices()]);
  return {
    content,
    services,
  };
}
