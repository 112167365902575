import type { TProduct, } from "@/types/product";
import SliderEdit from "../slider-edit";
import { useEffect, useState } from "react";
import type { TCategory } from "@/types/category";
import type { TSubcategory } from "@/types/subcategory";
import type { TBrand } from "@/types/brand";
import type { TPaginatedList } from "@/types/general";
import { ProductAPI, SubcategoryAPI } from "@/api";
import { PRODUCT_SEARCH_SORTS } from "@/consts/product.const";
import { useSearchParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";


type RelatedProductsProps = {
    categories: TCategory[];
    brands: TBrand[];
    productId?: string;
    name: string;
}

export default function RelatedProducts({ categories, brands, productId, name }: RelatedProductsProps) {
    const { watch, setValue } = useFormContext();
    const [products, setProducts] = useState<TPaginatedList<TProduct>>();
    const [searchParams] = useSearchParams();
    const [subcategories, setSubcategories] = useState<TSubcategory[]>([]);
    const selectedProductIds = watch(name);
    const selectedProducts = products?.items.filter(product => selectedProductIds.includes(product.id)) || [];
    const page = searchParams.get("page");

    async function onAdd(productId: string) {
        setValue(name, [...selectedProductIds, productId]);
    }

    async function onDelete(productId: string) {
        setValue(name, selectedProductIds.filter((id: string) => id !== productId));
    }

    async function onCategoryChange(categoryId: string) {
        const { data } = await SubcategoryAPI.getListByCategoryId(categoryId);
        setSubcategories(data.data.items);
    }

    useEffect(() => {
        async function fetchProducts() {
            const { data } = await ProductAPI.getList({ pagination: { page: Number(page) || 1, limit: 12 }, sorting: PRODUCT_SEARCH_SORTS.CREATED_AT_DSC, });
            setProducts(data.data);
        }

        fetchProducts();
    }, [page]);

    return <SliderEdit
        onAdd={onAdd}
        onDelete={onDelete}
        categories={categories}
        subcategories={subcategories}
        brands={brands}
        products={products}
        selectedProducts={selectedProducts}
        selectedProductId={selectedProductIds}
        paginationPath={"/products/" + (productId || "add")}
        onCategoryChange={onCategoryChange}
    />;
}
