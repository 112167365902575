import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { ProductAPI } from "@/api";
import { TabsContent } from "@/components/ui/tabs.tsx";
import HealthInspectionTable from "@/components/product-form/health-inspection/table.tsx";

type HealthTabContentProps = { id?: string };

export default function HealthTabContent({ id }: HealthTabContentProps) {
  const { setValue } = useFormContext();

  async function setInitialHealthData() {
    setValue("healthTable", await ProductAPI.getInspectionList(id));
  }

  useEffect(() => {
    setInitialHealthData();
    // eslint-disable-next-line
  }, []);

  return (
    <TabsContent value="health">
      <HealthInspectionTable />
    </TabsContent>
  );
}
