import { useRef, useState } from "react";
import { Input, InputProps } from "@/components/ui/input.tsx";
import { useOutsideClick } from "@/lib/use-click-outside.ts";
import { cn } from "@/lib/utils.ts";
import type { TPropSuggestions } from "@/types/product.ts";

type PropertyAutocompleteProps = Omit<InputProps, "onChange"> & {
  onChange: (value: string) => void;
  suggestions: TPropSuggestions[];
};

export default function PropertyAutocomplete({ value, onChange, name, suggestions, ...inputProps }: PropertyAutocompleteProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  useOutsideClick(wrapperRef, () => setShowOptions(false));

  function handleResultClick(result: string) {
    setShowOptions(false);
    onChange(result);
  }

  return (
    <>
      <div className="relative" ref={wrapperRef}>
        <Input
          value={value}
          name={name}
          onChange={({ target }) => onChange(target.value)}
          onFocus={() => setShowOptions(true)}
          {...inputProps}
        />
        {showOptions && suggestions.length > 0 && (
          <ul className="absolute mt-1 bg-popover border border-white/10 w-full overflow-hidden rounded-md shadow-lg text-white text-sm select-none">
            {suggestions.map((result) => {
              const resultValue = result[`prop_${name as "fa" | "en"}`];

              return (
                <li
                  onClick={() => handleResultClick(resultValue)}
                  className={cn(
                    "m-1 cursor-pointer p-2 hover:bg-[hsl(var(--accent))] active:bg-[hsl(var(--accent))] flex items-center",
                    value === resultValue && "bg-[hsl(var(--accent))]",
                  )}
                >
                  {resultValue}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
}
