import { cn } from "@/lib/utils";
import { X, Loader2, Check, Plus } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import ListData from "./list-data";
import Pagination from "./pagination";
import Price from "./price";
import { useNavigation, useSearchParams } from "react-router-dom";
import React from "react";
import { Button } from "./ui/button";
import type { TBrand } from "@/types/brand";
import type { TCategory } from "@/types/category";
import type { TSubcategory } from "@/types/subcategory";
import type { TProduct } from "@/types/product";
import type { TPaginatedList } from "@/types/general";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";

type SliderEditProps = {
    products?: TPaginatedList<TProduct>;
    categories: TCategory[];
    subcategories: TSubcategory[];
    brands: TBrand[];
    selectedProducts: (TProduct & { thumbnail?: string })[];
    selectedProductId: string[];
    addingProduct?: string;
    deletingProduct?: string;
    onAdd: (productId: string) => Promise<void> | undefined;
    onDelete: (productId: string) => Promise<void> | undefined;
    paginationPath: string;
    onCategoryChange?: (categoryId: string) => void; // TODO: Remove this
}

export default function SliderEdit({ products, categories, subcategories, brands, selectedProducts, selectedProductId, addingProduct = "", deletingProduct = "", onAdd, onDelete, paginationPath, onCategoryChange }: SliderEditProps) {
    const { t } = useTranslation();
    const navigation = useNavigation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [category, setCategory] = React.useState(searchParams.get("category") ?? "");
    const [subcategory, setSubcategory] = React.useState(searchParams.get("subcategory") ?? "");
    const [brand, setBrand] = React.useState(searchParams.get("brand") ?? "");

    return (
        <>
            <div className="min-h-52 my-4">
                {selectedProducts.length ? (
                    <div className="grid grid-cols-3 gap-2">
                        {selectedProducts.map((product) => (
                            <div key={product.id} className="p-2 border border-white/10 bg-gray-scale-5 flex gap-2">
                                <img src={product.thumbnail || product.image} className="w-[80px] min-h-[80px] bg-white object-cover" />
                                <div className="flex-1 relative flex flex-col justify-between">
                                    <span className="inline-block text-gray-scale-2 font-montserrat ">{product.name}</span>
                                    <span className="text-gray-scale-4 text-sm">
                                        <Price
                                            call_to_buy={product.call_to_buy}
                                            price={product.price}
                                            is_available={product.is_available}
                                            is_on_sale={product.is_on_sale}
                                            price_to_pay={product.price_to_pay}
                                        />
                                    </span>
                                    <div className="absolute -bottom-2.5 -left-2.5">
                                        <Button
                                            onClick={() => onDelete(product.id)}
                                            loading={deletingProduct === product.id}
                                            variant="secondary"
                                            size="icon"
                                            className="!text-gray-scale-4 hover:!text-primary"
                                        >
                                            <X />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-center text-gray-scale-4">
                        <Trans i18nKey="common.isEmpty" />
                    </div>
                )}
            </div>

            <div>
                <ListData
                    label={
                        <span className="text-xl">
                            <Trans i18nKey="sliders.productsList" />
                        </span>
                    }
                    actionsClassNames="flex items-center gap-2"
                    actions={
                        <>
                            {navigation.state === "loading" ? <Loader2 className="text-primary animate-spin" /> : null}
                            {/* Category Select */}
                            <Select
                                value={category}
                                onValueChange={(value) => {
                                    onCategoryChange?.(value);
                                    setCategory(value);
                                    setSearchParams((prevParams) => {
                                        prevParams.set("category", value);
                                        return prevParams;
                                    });
                                }}
                            >
                                <SelectTrigger className="w-[160px]">
                                    <SelectValue placeholder={t("prices.categories")} />
                                </SelectTrigger>
                                <SelectContent>
                                    {categories?.map((category) => (
                                        <SelectItem key={category.id} value={category.id}>
                                            {category.title.fa}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            {/* Subcategory Select */}
                            <Select
                                value={subcategory}
                                disabled={subcategories?.length === 0}
                                onValueChange={(value) => {
                                    setSubcategory(value);
                                    setSearchParams((prevParams) => {
                                        prevParams.set("subcategory", value);
                                        return prevParams;
                                    });
                                }}
                            >
                                <SelectTrigger className="w-[160px]">
                                    <SelectValue placeholder={t("prices.subcategories")} />
                                </SelectTrigger>
                                <SelectContent>
                                    {subcategories?.map((subcategory) => (
                                        <SelectItem key={subcategory.id} value={subcategory.id}>
                                            {subcategory.title.fa}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            {/* Brand Select */}
                            <Select
                                value={brand}
                                onValueChange={(value) => {
                                    setBrand(value);
                                    setSearchParams((prevParams) => {
                                        prevParams.set("brand", value);
                                        return prevParams;
                                    });
                                }}
                            >
                                <SelectTrigger className="w-[160px]">
                                    <SelectValue placeholder={t("prices.brands")} />
                                </SelectTrigger>
                                <SelectContent>
                                    {brands?.map((brand) => (
                                        <SelectItem key={brand.id} value={brand.id}>
                                            {brand.name.fa}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>

                            <Button
                                variant="ghost"
                                disabled={!category && !subcategory && !brand}
                                onClick={() => {
                                    setBrand("");
                                    setCategory("");
                                    setSubcategory("");
                                    setSearchParams({});
                                }}
                            >
                                <Trans i18nKey="prices.clearSearch"></Trans>
                            </Button>
                        </>
                    }
                >
                    {products?.items.length ? (
                        <div className="grid grid-cols-3 gap-2">
                            {products?.items.map((product) => (
                                <div key={product.id} className="p-2 border border-white/10 bg-gray-scale-5 flex gap-2">
                                    <img src={product.image} className="w-[80px] min-h-[80px] bg-white object-cover" />
                                    <div className="flex-1 relative flex flex-col justify-between">
                                        <span className="inline-block text-gray-scale-2 font-montserrat ">{product.name}</span>
                                        <Price
                                            call_to_buy={product.call_to_buy}
                                            price={product.price}
                                            is_available={product.is_available}
                                            is_on_sale={product.is_on_sale}
                                            price_to_pay={product.price_to_pay}
                                        />
                                        <div className="absolute -bottom-2.5 -left-2.5">
                                            <Button
                                                onClick={() => onAdd(product.id)}
                                                loading={addingProduct === product.id}
                                                variant={selectedProductId?.includes(product.id) ? "default" : "secondary"}
                                                size="icon"
                                                className={cn({
                                                    "!text-gray-scale-2 pointer-events-none": selectedProductId?.includes(product.id),
                                                    "!text-gray-scale-4 hover:!text-primary": !selectedProductId?.includes(product.id),
                                                })}
                                            >
                                                {selectedProductId?.includes(product.id) ? <Check /> : <Plus />}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-gray-scale-4 text-center">
                            <Trans i18nKey="common.isEmpty" />
                        </div>
                    )}
                </ListData>
                {products?.items.length ? (
                    <Pagination
                        limit={12}
                        className="mt-4"
                        path={paginationPath}
                        currentPage={products?.pagination.current_page}
                        totalPage={products?.pagination.total_pages}
                    />
                ) : null}
            </div>
        </>
    );
}
