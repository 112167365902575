import CommentOverview from "@/components/comment/overview.tsx";
import { FormProvider, useForm } from "react-hook-form";
import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import type { TComment } from "@/types/comment.ts";
import { CommentsAPI } from "@/api";
import { Trans, useTranslation } from "react-i18next";
import { DialogClose, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { z } from "zod";
import { getRequiredStrSchema } from "@/lib/utils/validation.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useRef } from "react";
import { toast } from "@/components/ui/use-toast.ts";
import { useRevalidator } from "react-router-dom";

export default function AdminReplyDialogContent(comment: TComment) {
  const { revalidate } = useRevalidator();
  const form = useForm({ defaultValues: { reply: "" }, resolver: zodResolver(schema) });
  const {
    handleSubmit,
    formState: { errors, submitCount, isSubmitting },
  } = form;
  const { t } = useTranslation();
  const closeRef = useRef<HTMLButtonElement>(null);

  async function submit({ reply }: { reply: string }) {
    const { message } = await CommentsAPI.replyByAdmin({ id: comment.id, text: reply });
    toast({ description: message.fa });
    revalidate();
  }

  useEffect(() => {
    if (submitCount && !errors?.reply) {
      closeRef.current!.click();
    }
  }, [submitCount]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-4">
        <DialogHeader>
          <DialogTitle>
            <Trans i18nKey="comments.yourResponse" />
          </DialogTitle>
        </DialogHeader>
        <CommentOverview {...comment} />
        <FormField
          control={form.control}
          name="reply"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Textarea
                  rows={5}
                  placeholder={t("comments.yourResponsePlaceholder")}
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" loading={isSubmitting}>
          <Trans i18nKey="comments.confirm" />
        </Button>
        <DialogClose ref={closeRef} />
      </form>
    </FormProvider>
  );
}

const schema = z.object({
  reply: getRequiredStrSchema(),
});
