import { NavLink, useLoaderData } from "react-router-dom";
import Page from "@/components/page.tsx";
import Pagination from "@/components/pagination.tsx";
import { Trans, useTranslation } from "react-i18next";
import { dict } from "@/dictionary";
import { TRepairRequestsData } from "@/types/repair.type.ts";
import ListData from "@/components/list-data.tsx";
import RepairRequestCard from "@/pages/repair/requests/repair-request-card.tsx";
import RepairDateTimeSection from "@/pages/repair/requests/date-time-section";
import { buttonVariants } from "@/components/ui/button.tsx";
import { Loader2 } from "lucide-react";

const pageTitle = dict.repairs.requestsTitle;
const pagePath = "/repair";

export default function RepairRequests() {
  const { t } = useTranslation();
  const { times, items, total_pages, current_page, filters } = useLoaderData() as TRepairRequestsData;

  return (
    <Page
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: pageTitle }]}
      title={pageTitle}
      action={linkToEditors}
    >
      <main className="bg-white/5 p-10 mb-5">
        <RepairDateTimeSection initialTimes={times} />
      </main>
      <ListData filters={filters} path={pagePath}>
        {items.length ? (
          <ul className="flex flex-col gap-4">
            {items.map((repairRequest) => (
              <li key={repairRequest.id}>
                <RepairRequestCard {...repairRequest} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center font-black text-lg py-8 text-gray-scale-4">
            <Trans i18nKey="common.isEmpty" />
          </div>
        )}
      </ListData>
      {items.length ? <Pagination path={pagePath} currentPage={current_page} totalPage={total_pages} /> : null}
    </Page>
  );
}

const linkToEditors = (
  <NavLink to={"edit-texts"} className={buttonVariants({ variant: "secondary", className: "w-24" })}>
    {({ isPending }) =>
      isPending ? (
        <Loader2 className="animate-spin" />
      ) : (
        <div className="flex items-center gap-1">{dict.repairs.editorsPage}</div>
      )
    }
  </NavLink>
);
