import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import i18n from "@/lib/i18n";
import { dict } from "@/dictionary";

export default [
  // { name: i18n.t("sidebar.home"), link: "/" },
  { name: i18n.t("sidebar.orders"), link: "/orders", permissions: [ADMIN_PERMISSIONS.READ_ORDER] },
  {
    name: i18n.t("sidebar.products"),
    link: "/products",
    permissions: [
      ADMIN_PERMISSIONS.READ_PRODUCT,
      ADMIN_PERMISSIONS.READ_CATEGORY,
      ADMIN_PERMISSIONS.READ_SUB_CATEGORY,
      ADMIN_PERMISSIONS.READ_TAG,
      ADMIN_PERMISSIONS.READ_BRAND,
      ADMIN_PERMISSIONS.READ_MODEL,
    ],
  },
  { name: i18n.t("sidebar.users"), link: "/users", permissions: [ADMIN_PERMISSIONS.READ_USER] },
  { name: i18n.t("sidebar.comments"), link: "/comments", permissions: [ADMIN_PERMISSIONS.READ_COMMENT] },
  {
    name: dict.repairs.title,
    link: "/repair?" + new URLSearchParams({ status: "all", page: "1", limit: "10" }),
    permissions: [ADMIN_PERMISSIONS.READ_COMMENT, ADMIN_PERMISSIONS.READ_REPAIRS, ADMIN_PERMISSIONS.WRITE_REPAIRS],
  },
  { name: i18n.t("sidebar.admins"), link: "/admins", permissions: [ADMIN_PERMISSIONS.READ_ADMIN] },
  { name: i18n.t("sidebar.siteSettings"), link: "/website", permissions: [ADMIN_PERMISSIONS.READ_STATICS] },
  { name: i18n.t("sidebar.logs"), link: "/logs", permissions: [ADMIN_PERMISSIONS.READ_LOG] },
];
