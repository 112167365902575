import { DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { dict } from "@/dictionary";
import { DateTime } from "@/pages/repair/requests/date-time-section/date-time.tsx";
import { DialogClose } from "@radix-ui/react-dialog";
import { buttonVariants } from "@/components/ui/button.tsx";
import { TRepairDateTime, TRepairDayStatus } from "@/types/repair.type.ts";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { useState } from "react";
import { RepairAPI } from "@/api";
import { toast } from "@/components/ui/use-toast.ts";
import DateObject from "react-date-object";

const statuses: TRepairDayStatus[] = ["available", "busy", "closed"];

type EditRepairTimesProps = {
  times: TRepairDateTime[];
  isPending: boolean;
  date: DateObject;
  onDateChange: (date: DateObject) => void;
  onTimesUpdate: () => void;
};

export default function EditRepairTimes({ times, isPending, date, onDateChange, onTimesUpdate }: EditRepairTimesProps) {
  const [updatedTimes, setUpdatedTimes] = useState<TRepairDateTime[]>([]);

  function changeTimeStatus(dateTime: TRepairDateTime) {
    setUpdatedTimes((prevState) => {
      const updatedTimeIndex = prevState.findIndex(({ time }) => time === dateTime.time);
      if (updatedTimeIndex !== -1) {
        const clonedPrevState = prevState.slice();
        clonedPrevState[updatedTimeIndex] = dateTime;
        return clonedPrevState;
      } else {
        return prevState.concat(dateTime);
      }
    });
  }

  async function saveUpdatedTimes() {
    if (updatedTimes.length) {
      const { message } = await RepairAPI.setDates(updatedTimes);
      toast({
        description: message.fa,
      });
      onTimesUpdate();
    }
  }

  async function handleDateChange(date: DateObject) {
    onDateChange(date);
    setUpdatedTimes([]);
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          <h3>{dict.repairs.activeDays}</h3>
        </DialogTitle>
      </DialogHeader>
      <DateTime
        times={times}
        date={date}
        onDateChange={handleDateChange}
        isPending={isPending}
        renderTimeStatus={(dateTime) => (
          <Select
            onValueChange={(status) => changeTimeStatus({ ...dateTime, status } as TRepairDateTime)}
            defaultValue={dateTime.status}
          >
            <SelectTrigger className="w-40">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {statuses.map((status) => (
                <SelectItem key={status} value={status}>
                  {dict.repairs[status]}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
      <DialogFooter>
        <DialogClose onClick={saveUpdatedTimes} className={buttonVariants()}>
          {dict.common.save}
        </DialogClose>
      </DialogFooter>
    </>
  );
}
