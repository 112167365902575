import { RepairAPI } from "@/api";
import {
  TRepairFilterStatus,
  TRepairRequestsData,
  TRepairRequestsParams,
  TStatusBasedCount,
  TStatusCount,
} from "@/types/repair.type.ts";
import { LoaderFunctionArgs } from "react-router-dom";
import { TListFilter } from "@/components/list-data.tsx";
import { dict } from "@/dictionary";
import { formatForAPI } from "@/lib/utils/date-time-format.ts";
import DateObject from "react-date-object";

export default async function loadRepairRequests({ request }: LoaderFunctionArgs): Promise<TRepairRequestsData> {
  const params = Object.fromEntries(new URL(request.url).searchParams.entries());
  const [times, repairRequestData, statusCounts] = await Promise.all([
    RepairAPI.getDateTimes(formatForAPI(new DateObject())),
    RepairAPI.getRequests(params as TRepairRequestsParams),
    RepairAPI.getStatusCount(),
  ]);

  return { ...repairRequestData, filters: makeFilters(statusCounts), times };
}

function makeFilters(statusCounts: TStatusCount[]): TListFilter[] {
  const filterStatuses: TRepairFilterStatus[] = ["all", "new", "visited", "done", "canceled"];
  const statusBasedCount = statusCounts.reduce(
    (final, { status, count }) => ({ ...final, [status]: count }),
    {} as TStatusBasedCount,
  );
  return filterStatuses.map((status) => ({
    count: statusBasedCount[status],
    label: dict.repairs[status],
    id: status,
  }));
}
