import { CommentsAPI } from "@/api";
import { TComment } from "@/types/comment.ts";
import { ReplyIcon, Trash2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";
import { Button, buttonVariants } from "../ui/button.tsx";
import { toast } from "../ui/use-toast.ts";
import { COMMENT_STATUS } from "@/consts/comments.const.ts";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog.tsx";
import CommentOverview from "@/components/comment/overview.tsx";

import AdminReplyDialogContent from "@/components/comment/admin-reply.tsx";

type TProps = { data: TComment };
export default function CommentCard(props: TProps) {
  const { data } = props;
  const revalidator = useRevalidator();
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [rejectLoading, setRejectLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const STATUS_LABELS = {
    [COMMENT_STATUS.CONFIRMED]: t("comments.confirmed"),
    [COMMENT_STATUS.REJECTED]: t("comments.rejected"),
  };

  const primaryProductImage = data.product.images.find((item) => item.is_primary);

  const onConfirm = async () => {
    try {
      setConfirmLoading(true);
      const resp = await CommentsAPI.confirmById(data.id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setConfirmLoading(false);
    }
  };

  const onReject = async () => {
    try {
      setRejectLoading(true);
      const resp = await CommentsAPI.rejectById(data.id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setRejectLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setDeleteLoading(true);
      const resp = await CommentsAPI.deleteById(data.id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="bg-gray-scale-5 p-4">
      <CommentOverview {...data} />
      {data.admin_comment && (
        <div className="py-3 mb-3 border-b border-white/5">
          <div className="flex items-start gap-2">
            <ReplyIcon size={18} />
            <span>{t("comments.admin")}</span>
          </div>
          <p className="text-sm font-semibold">{data.admin_comment}</p>
        </div>
      )}
      <footer className="mt-3 flex items-center">
        <div className="flex items-center flex-1">
          {primaryProductImage ? (
            <img src={primaryProductImage.thumbnail} className="w-[58px] h-[58px] bg-white object-cover ml-4" />
          ) : null}
          <div>
            <h3 className="mb-2 font-montserrat">{data.product.name}</h3>
            <div className="flex items-center text-gray-scale-4 text-xs">
              <span>{data.product.category.title.fa}</span>&nbsp;|&nbsp;
              <div className="flex items-center gap-2">
                <img src={data.product.brand.logo} className="h-4" />
                <span className="font-montserrat">{data.product.brand.name.en}</span>
              </div>
              {data.product.price.is_used ? (
                <>
                  &nbsp;|&nbsp;
                  <span>
                    <Trans i18nKey="comments.isUsed" />
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {data.status === COMMENT_STATUS.PENDING ? (
            <div className="w-64 grid grid-cols-2 gap-2">
              <Button onClick={onConfirm} loading={confirmLoading} disabled={deleteLoading || rejectLoading}>
                <Trans i18nKey="comments.confirm" />
              </Button>
              <Button
                onClick={onReject}
                loading={rejectLoading}
                disabled={deleteLoading || confirmLoading}
                variant="secondary"
              >
                <Trans i18nKey="comments.reject" />
              </Button>
            </div>
          ) : (
            <div className="w-44 py-2 bg-gray-scale-5 text-gray-scale-4 flex items-center justify-center">
              {STATUS_LABELS[data.status]}
            </div>
          )}
          <Dialog>
            <DialogTrigger className={buttonVariants({ variant: "secondary", size: "icon" })}>
              <ReplyIcon size={20} />
            </DialogTrigger>
            <DialogContent className="max-w-3xl">
              <AdminReplyDialogContent {...data} />
            </DialogContent>
          </Dialog>
          <Button
            onClick={onDelete}
            size="icon"
            variant="destructive"
            disabled={rejectLoading || confirmLoading}
            loading={deleteLoading}
          >
            <Trash2 size={20} />
          </Button>
        </div>
      </footer>
    </div>
  );
}
