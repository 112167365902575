import { useLoaderData } from "react-router-dom";
import ContentsForm from "@/pages/repair/edit-texts/content-form.tsx";
import type { TRepairLoaderData } from "@/types/repair.type.ts";
import Page from "@/components/page.tsx";
import { dict } from "@/dictionary";
import { useTranslation } from "react-i18next";
import ServicesTable from "@/pages/repair/edit-texts/services-table.tsx";

const pageTitle = dict.repairs.editorsPage;

export default function Repair() {
  const { content, services } = useLoaderData() as TRepairLoaderData;
  const { t } = useTranslation();

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        {
          label: dict.repairs.requestsTitle,
          link: "/repair?" + new URLSearchParams({ status: "all", page: "1", limit: "10" }),
        },
        { label: pageTitle },
      ]}
      title={pageTitle}
    >
      <main className="flex flex-col gap-10 bg-white/5 p-10">
        <ContentsForm defaultValues={content} />
        <div className="flex flex-col gap-5">
          <h3>{dict.repairs.services}</h3>
          <ServicesTable services={services} />
        </div>
      </main>
    </Page>
  );
}
