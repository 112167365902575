import { Input } from "@/components/ui/input.tsx";
import { useTranslation } from "react-i18next";
import type { TInspectionHistoryItem } from "@/types/used-product.type.ts";
import { EyeIcon } from "lucide-react";
import { type ChangeEvent, useState } from "react";

type HistoryProps = { history: TInspectionHistoryItem[] };

export default function InspectionHistory({ history }: HistoryProps) {
  const { t } = useTranslation();
  const [filteredHistory, setFilteredHistory] = useState<TInspectionHistoryItem[]>(history);

  function filterHistory({ target }: ChangeEvent<HTMLInputElement>) {
    setFilteredHistory(history!.filter(({ product_name }) => product_name.includes(target.value)));
  }

  return (
    <div className="h-full">
      <Input onChange={filterHistory} className="bg-white/5 mb-5" placeholder={t("common.search")} />
      <div className="flex flex-col divide-y max-h-[30rem] overflow-auto pe-2">
        {filteredHistory.map(({ id, series_id, product_name, report_url }) => (
          <div key={id} className="text-sm grid grid-cols-[1fr_1fr_1.5rem] gap-5 py-4">
            <div>
              <span>{t("healthInspection.seriesNumber")}: </span>
              <span>{series_id}</span>
            </div>
            <div>
              <span>{t("healthInspection.model")}: </span>
              <span>{product_name}</span>
            </div>
            <a href={report_url} target="_blank">
              <EyeIcon className="cursor-pointer" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
